<template>
  <AVNormalSummary
    :sorted-result="result.resultDataForDisplay"
    :total-count="totalCount"
    :hide-percentage="!contest.resultPercentages"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { flattenOptions } from "@/entrypoints/shared/contest_utilities";
import type { ConferenceContest, PropType, Locale, ConferenceOption, Result } from "@/types";

export default defineComponent({
  name: "NormalSummary",
  props: {
    result: {
      type: Object as PropType<Result>,
      required: true,
    },
    contest: {
      type: Object as PropType<ConferenceContest>,
      required: true,
    },
    locale: {
      type: String as PropType<Locale>,
      default: "en",
    },
  },
  computed: {
    sortedCounts() {
      if (!this.resultData) return [];
      return this.resultData.optionSortedCounts;
    },
    counts() {
      if (!this.resultData) return [];
      return this.resultData.optionCounts;
    },
    elected() {
      return this.resultData.elected;
    },
    tied() {
      return this.resultData.tied || [];
    },
    totalCount() {
      if (this.contest.disregardBlankVotes) {
        return Object.entries(this.counts)
          .filter(([reference, _count]) => reference.toString() !== "blank")
          .reduce((sum: number, [_reference, count]: any[]) => sum + count, 0);
      } else if (this.contest.consensusRequired && this.contest.countUnvoicedVotesAsBlank) {
        return this.contest.disregardWeight
          ? this.result.voterCounts.activeEligiblesOrVoted
          : this.result.voterCounts.activeEligiblesOrVotedWeight;
      } else {
        return Object.values(this.counts).reduce((sum: number, count: number) => sum + count, 0);
      }
    },
    resultData() {
      return this.result.resultData;
    },
    sortedResult() {
      const results: any[] = [];

      this.sortedCounts.forEach((sortedCount: any) => {
        const result: any = {};
        result.reference = sortedCount[0];
        result.count = sortedCount[1];
        result.elected = this.elected.includes(sortedCount[0]);
        result.tied = this.tied.includes(sortedCount[0]);

        const option = this.flattenOptions(this.contest.options).find((option: ConferenceOption) =>
          option.reference === sortedCount[0]);

        if (option) {
          result.title = { ...option.title };
          if (option.image) result.image = option.image;
        }

        results.push(result);
      });

      if (this.contest.blankOption !== "disabled") {
        const result = {
          reference: "blank",
          title: {
            [this.locale]: this.$t("js.standard_option.blank", this.locale)
          },
          count: this.counts["blank"],
        }

        results.push(result);
      }

      return results;
    },
  },
  methods: {
    flattenOptions,
  },
});
</script>
